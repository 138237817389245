// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  API_URL: 'https://api.grandeella-koeln.de/api/', // 'https://api.joysburger.de/api/', // 
  BASE_URL:  'https://api.grandeella-koeln.de',// 'https://api.joysburger.de', //
  DEFAULT_LANG: 'de',
  APP_VERSION: '0.1', // change app version to force clear cache
  GOOGLE_COUNTRIES: ["de"], // for google address
  GOOGLE_LOGIN_WEB_CLIENT_ID: '65878389371-fevipp0727iamaaimgfrtqtbo20hcnhv0.apps.googleusercontent.com',
  FACEBOOK_LOGIN_APP_ID: '10322122325652334',
  // theme configuration
  DEFAULT_PRIMARY_COLOR: '#cc1fb5',
  DEFAULT_PRIMARY_TEXT_COLOR: '#cc8a1f'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
